import fr from "assets/i18n/fr.json"
import es from "assets/i18n/es.json"
import ru from "assets/i18n/ru.json"
import it from "assets/i18n/it.json"
import de from "assets/i18n/de.json"
import cs from "assets/i18n/cs.json"
import el from "assets/i18n/el.json"
import nl from "assets/i18n/nl.json"
import pl from "assets/i18n/pl.json"
import pt from "assets/i18n/pt.json"
import ro from "assets/i18n/ro.json"
import tr from "assets/i18n/tr.json"

export const dictionaries = {
  fr,
  es,
  ru,
  it,
  de,
  cs,
  el,
  nl,
  pl,
  pt,
  ro,
  tr,
}
