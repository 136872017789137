import nativeFetch from "isomorphic-fetch"
import { isPlainObject, isArray } from "lodash"
import URI from "urijs"
import { NetworkError } from "utils/networkError"
//import messages from "components/messages"

export async function fetch(target, userOptions = {}) {
  let response
  let data

  let options = Object.assign({}, userOptions, {
    headers: Object.assign(
      {
        [`Accept`]: `application/json`,
      },
      userOptions.headers
    ),
  })

  if (isPlainObject(options.body) || isArray(options.body)) {
    options.headers[`Content-Type`] = `application/json`
    options.body = JSON.stringify(options.body)
  }

  try {
    // The "String(target)" cast is required, because typeof target might not be equal to "string", even if target IS a string
    response = await nativeFetch(String(target), options)
  } catch (error) {
    throw new NetworkError(error)
  }
  //otherwise it throws an Exception on 204

  if (response.status === 204 && response.statusText === "No Content") {
    data = { success: { data: [] } }
  } else {
    try {
      data = await response.json()
    } catch (error) {
      throw new NetworkError(error)
    }
  }
  // if (response.headers.get(`Date`))
  //     setServerTime(moment.utc(response.headers.get(`Date`), SERVER_DATE_FORMAT));
  if (!response.ok) {
    throw new NetworkError(response, data.error)
  } else {
    return data.success
  }
}

export async function fetchApiData(
  target,
  {
    event = null,
    reportLocator = null,
    eventLocator = event,
    session = null,
    options = {},
  } = {}
) {
  //eslint-disable-next-line no-undef
  const API_BASE = new URI(process.env.API_BASE).normalize()
  if (new URI(target).is(`relative`))
    target = new URI(target).absoluteTo(API_BASE).toString()

  let headers = {}

  if (session && session.token) headers[`Wisembly-Token`] = session.token

  let password = null

  if (session && !password && eventLocator)
    password = session.secrets.get(eventLocator.locator, null)

  if (session && !password && reportLocator)
    password = session.secrets.get(reportLocator.locator, null)

  if (password) headers[`Wisembly-Password`] = password

  const response = await fetch(target, Object.assign({ headers }, options))

  return response.data
}

export const getRegisterErrorMessage = code => {
  switch (code) {
    case `email_unavailable`:
      return `This email address is already taken. You might want to login.`

    case `invalid_parameters`:
      return `An error occurred, please check your credential.`

    default:
      return ``
  }
}

export const getSessionOpenErrorMessage = code => {
  switch (code) {
    case `email_unavailable`:
      return `This email address is already taken. You might want to login.`

    case `invalid_parameters`:
      return `An error occurred, please check your credential.`
    case `user_blocked`:
      return `Too many login attempts. Please retry in a few minutes.`

    case `user_not_found`:
    case `wrong_secret`:
    default:
      return `There is a mistake in your email or password.`
  }
}

export const getForgetPasswordErrorMessage = code => {
  switch (code) {
    case `user_not_found`:
      return `There is a mistake in your email or password.`

    case `already_requested`:
      return `There is already a request.`

    default:
      return `An error occured`
  }
}
//
// export const getErrorMessage = error => {
//   switch (error.code) {
//     case `email_unavailable`:
//       return messages.emailUnavailable
//
//     case `invalid_parameters`:
//       return messages.invalidParameters
//     case `user_blocked`:
//       return messages.userBlocked
//     case `user_not_found`:
//       return messages.userNotFound
//
//     case `already_requested`:
//       return messages.alreadyRequested
//     case `wrong_secret`:
//     default:
//       return messages.wrongSecret
//   }
// }
