export function isNetworkError(error, status = null) {
  if (!error || error.name !== `NetworkError`) return false

  if (status !== null && error.statusCode !== status) return false

  return true
}

export class NetworkError extends Error {
  constructor(
    {
      status = 0,
      statusCode = status,
      statusText = `UNKNOWN`,
      message = statusCode
        ? `The server answered with an HTTP ${statusCode} (${statusText})`
        : `Unexpected network error`,
    } = {},
    extra = null
  ) {
    super(message)

    this.name = `NetworkError`

    this.statusCode = statusCode
    this.statusText = statusText

    this.extra = extra
  }
}
