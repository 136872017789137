const globalStore = {}

export const getWindow = (key, placeholder = false) => {
  const store = typeof window === `undefined` ? globalStore : window

  if (typeof store[key] === `undefined`) return placeholder

  return store[key]
}

export const setWindow = (key, value) => {
  const store = typeof window === `undefined` ? globalStore : window
  store[key] = value
}
