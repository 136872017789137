import React from "react"

import clsx from "clsx"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import Logo from "./Logo"
// import Language from "./Language"
import ConnexionButton from "./connexion-button"
import { getUser, isLoggedIn } from "services/auth"

const useStyles = makeStyles(theme => ({
  root: {
    //height: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      zIndex: theme.zIndex.tooltip,
    },
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.appBar,
    },
    boxShadow: theme.shadows[1],
    padding: "5px 0",
    backgroundColor: "white",
  },
  largeSection: {
    flex: "1 auto",
    padding: "0 10px 0 20px",
  },
  smallSection: {
    [theme.breakpoints.up("sm")]: {
      width: "353px",
    },
    padding: "0 20px 0 10px",
  },

  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
  },
  anchorTop: {
    height: "100%",
  },
  username: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary,
    borderLeft: `1px solid ${theme.palette.grey[200]}`,
  },
}))

const TopBar = () => {
  const classes = useStyles()

  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        alignItems="center"
        className={clsx("Header", classes.root)}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className={classes.largeSection}
        >
          <Box display="flex" alignItems="flex-start" flexDirection="row">
            <Logo />
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className={clsx(classes.smallSection, "MenuRightSection")}
        >
          {/*<Box display="flex" flex={1} justifyContent="flex-start">
            <Language />
          </Box>*/}

          <Box display="flex" flex={1} justifyContent="flex-end">
            <ConnexionButton />

            <Box
              display={isLoggedIn() ? "flex" : "none"}
              alignItems="center"
              justifyContent="center"
              className={clsx("Username", classes.username)}
            >
              {getUser().name}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TopBar
