import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import { withStyles } from "@material-ui/core/styles"
import { handleLogin, isValid } from "services/auth"
import emitter, { events } from "utils/emitter"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Password from "./password"
import { _t } from "utils/i18n"
import OAuthLinks from "./oAuthLinks"

const styles = theme => ({
  focus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  root: {
    paddingTop: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    paddingBottom: theme.spacing(5),
    maxWidth: "400px",
    textAlign: "center",
  },
  description: {
    maxWidth: "400px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: "400px",
  },
  linkForgotPassword: {
    color: theme.wisemblyColors.grey,
    textDecoration: "underline",
    alignSelf: "flex-end",
  },
  backButton: {
    marginTop: theme.spacing(2),
  },
})

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.wisemblyColors.white,
    backgroundColor: theme.wisemblyColors.green,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.green,
    },
    width: "100%",
  },
}))(Button)

class Signin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: this.props.password || "",
      email: this.props.email || "",
      errors: {},
      passwordErrors: "",
      isValid: false,
    }

    this.state.isValid = this.state.password.length && this.state.email.length

    this.handleChange = this.handleChange.bind(this)
  }

  handleSubmit = async event => {
    event.preventDefault()
    const result = await handleLogin(this.state)

    if (result) {
      emitter.emit(events.hiddenLogin)
      return
    }

    this.setState({
      isValid: false,
      errors: {
        password: _t("Wrong password"),
      },
    })
  }

  isValid() {
    const errors = isValid({ ...this.state, type: "signin" })
    this.setState({ isValid: Object.keys(errors).length === 0, errors })
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState(
      {
        [name]: value,
      },
      this.isValid
    )
  }

  render() {
    const { classes, showForm } = this.props
    const { email, password, errors, isValid } = this.state
    const { title, description } = this.props.login

    return (
      <Box className={clsx("ConnectFormSignIn", classes.focus)}>
        <Box display="flex" className={classes.root}>
          <Typography
            align="center"
            className={classes.title}
            variant="h3"
            dangerouslySetInnerHTML={{
              __html: title
                ? title
                : _t(
                    "Hello, welcome to Wisembly!<br />Would you like to log in<br />with your account?"
                  ),
            }}
          />

          {description && (
            <Box
              className={clsx("SigninDesc", classes.description)}
              variant="div"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}

          <form onSubmit={this.handleSubmit} className={classes.form}>
            <TextField
              margin="normal"
              variant="outlined"
              label={_t("Your email")}
              name="email"
              value={email}
              onChange={this.handleChange}
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email : ""}
              id="inputEmail"
            />
            <Password
              password={password}
              error={errors.password}
              handleChange={this.handleChange}
              label={_t("Your password")}
            />
            <Button
              className={classes.linkForgotPassword}
              onClick={() => showForm("forgot_pwd", this.state.email)}
              id="forgotPasswordButton"
            >
              <Typography align="right" variant="body2">
                {_t("Forgot your password")}
              </Typography>
            </Button>
            <ColorButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!isValid}
              id="loginButton"
            >
              {_t("Login")}
            </ColorButton>
          </form>
          <OAuthLinks />
          <Button
            onClick={() =>
              showForm("signup", this.state.email, this.state.password)
            }
            className={classes.backButton}
            id="goToRegister"
          >
            <Typography variant="subtitle1">
              {_t("👋 You don't have an account? Please register")}
            </Typography>
          </Button>
        </Box>
      </Box>
    )
  }
}

Signin.propTypes = {
  classes: PropTypes.object,
  showForm: PropTypes.func,
  login: PropTypes.object,
  email: PropTypes.string,
  password: PropTypes.string,
  page: PropTypes.object,
}

Signin.defaultProps = {
  email: "",
  password: "",
  page: {},
}

export default withStyles(styles)(Signin)
