import EventEmitter from "eventemitter3"
import memoize from "lodash/memoize"

import { setWindow } from "utils/window"

export const events = {
  showLogin: `showLoginModal`,
  hiddenLogin: `hiddenLoginModal`,
  userConnected: `userConnected`,
  userLogout: `userLogout`,
  userEventsUpdated: `userEventsUpdated`,
  userEventsClear: `userEventsClear`,
  confirmationEmailSent: `confirmationEmailSent`,
  showNotWizMemberFirewall: `showNotWizMemberFirewall`,
  hideNotWizMemberFirewall: `hideNotWizMemberFirewall`,
  showUnverifiedFirewall: `showUnverifiedFirewall`,
  hiddenFirewall: `hiddenFirewall`,
  showPreliminaryForm: `showPreliminaryForm`,
  hasAnsweredPreliminaryForm: `hasAnsweredPreliminaryForm`,
  userRegistered: "userRegistered",
}

const getEmitter = memoize(() => {
  const emitter = new EventEmitter()

  return emitter
})

const emitter = getEmitter()

// expose it to window for js custom scripts
setWindow(`emitter`, emitter)

export function mountEvents(events, context) {
  for (const event in events) {
    const methodName = events[event]

    emitter.on(
      event,
      typeof methodName === "function" ? methodName : context[methodName],
      context
    )
  }
}

export function unmountEvents(events, context) {
  for (const event in events) {
    const methodName = events[event]

    emitter.off(
      event,
      typeof methodName === "function" ? methodName : context[methodName],
      context
    )
  }
}

export default emitter
