import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { handleLostPassword, isValid } from "services/auth"
import OAuthLinks from "./oAuthLinks"
import { _t } from "utils/i18n"

const styles = theme => ({
  focus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  root: {
    paddingTop: theme.spacing(8),
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "400px",
  },
  backButton: {
    marginTop: theme.spacing(2),
  },
})

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.wisemblyColors.white,
    backgroundColor: theme.wisemblyColors.green,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.green,
    },
    width: "100%",
  },
}))(Button)

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: this.props.email || "",
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this)
    this.state.isValid = this.state.email.length
  }

  handleSubmit = async event => {
    event.preventDefault()
    const result = await handleLostPassword(this.state)

    if (result) {
      alert(_t("Instructions sent. Check your emails"))
    }
  }

  isValid() {
    const errors = isValid({ ...this.state, type: "forgot_pwd" })
    console.log("hello")
    this.setState({ isValid: Object.keys(errors).length === 0, errors })
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState(
      {
        [name]: value,
      },
      this.isValid
    )
  }

  render() {
    const { classes, showForm } = this.props
    const { email, errors, isValid } = this.state

    return (
      <Box className={clsx("ForgotPasswordPage", classes.focus)}>
        <Box display="flex" className={classes.root}>
          <form onSubmit={this.handleSubmit} className={classes.form}>
            {/* prettier-ignore */}
            <Typography variant="subtitle2">
              {_t("We will send instructions on how to change your password to this e-mail address.")}
            </Typography>
            <TextField
              margin="normal"
              variant="outlined"
              label={_t("Your email")}
              name="email"
              value={email}
              onChange={this.handleChange}
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email : ""}
            />
            <ColorButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!isValid}
            >
              {_t("Send information")}
            </ColorButton>
          </form>
          <OAuthLinks />
          <Button
            onClick={() => showForm("signin", this.state.email)}
            className={classes.backButton}
            id="backToConnection"
          >
            <Typography variant="subtitle1">
              {_t("👋 Back to connexion")}
            </Typography>
          </Button>
        </Box>
      </Box>
    )
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object,
  showForm: PropTypes.func,
  email: PropTypes.string,
}

ForgotPassword.defaultProps = {
  email: "",
}

export default withStyles(styles)(ForgotPassword)
