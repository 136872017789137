import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import images from "assets/img"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  },
  logo: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.spacing(5),
      minWidth: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: theme.spacing(5),
      minWidth: theme.spacing(4),
    },
  },
}))

const Logo = () => {
  const classes = useStyles()

  return (
    <Box className={clsx("Logo", classes.root)}>
      <img
        className={classes.img}
        component="img"
        src={images.WisemblyLogoLight}
        alt={`logo`}
      />
    </Box>
  )
}

export default Logo
