import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { isLoggedIn, logout } from "services/auth"
import emitter, { events } from "utils/emitter"

import { _t } from "utils/i18n"

const useStyles = makeStyles(theme => ({
  root: {
    //height: theme.spacing(5),
    [theme.breakpoints.down("xs")]: {
      zIndex: theme.zIndex.tooltip,
    },
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.appBar,
    },
    boxShadow: theme.shadows[1],
    padding: "5px 0",
  },
}))

const ConnectButton = () => {
  const classes = useStyles()
  return (
    <Box className={clsx("Login", classes.button)}>
      {isLoggedIn() ? (
        <Button
          href="/"
          onClick={event => {
            event.preventDefault()
            logout(() => emitter.emit(events.userLogout))
          }}
        >
          {_t("Logout")}
        </Button>
      ) : (
        <Button
          onClick={event => {
            event.preventDefault()
            emitter.emit(events.showLogin)
          }}
        >
          {_t("Connect")}
        </Button>
      )}
    </Box>
  )
}

export default ConnectButton
