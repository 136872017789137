let basil = { get: () => null, set: () => null }

export const getBasilStorages = () => {
  let storages = ["cookie", "session", "local", "memory"]

  try {
    storages = JSON.parse(process.env.BASIL_STORAGES)
  } catch (error) {
    console.warn("Wrong storages given, should be JSON parsable")
  }

  return storages
}

if (typeof window !== `undefined`) {
  const Basil = require("basil.js")

  basil = new Basil({
    namespace: process.env.BASIL_NAMESPACE,
    storages: getBasilStorages(),
    domain: process.env.BASIL_COOKIES_DOMAIN || window.location.hostname,
    expireDays: 31,
    secure: true,
    sameSite: "lax",
  })
}

export const setCookie = ({ key, value, conf = {} }) => {
  basil.set(key, value, {
    storages: getBasilStorages(),
    expireDays: 31,
    secure: true,
    sameSite: "lax",
    ...conf,
  })
}

export const getCookie = ({ key, conf = {} }) => {
  return basil.get(key, {
    storages: getBasilStorages(),
    ...conf,
  })
}

export default basil
