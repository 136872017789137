import React, { Component } from "react"
import PropTypes from "prop-types"
import TopBar from "components/top-bar"
import Box from "@material-ui/core/Box"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import Container from "@material-ui/core/Container"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"

import images from "assets/img/index"
import clsx from "clsx"
import { isTopBarActive, isHeaderActive, saveRedirectUrl } from "utils"
import LandingHeader from "components/landing/header"
import Login from "components/login"
import Form from "components/form"
import Firewall from "components/firewall"
import Alert from "components/alert"
import { isLoggedIn, getUser, isVerified } from "services/auth"
import emitter, { events } from "utils/emitter"

import i18n, { _t, getLocale } from "utils/i18n"

import { dictionaries } from "./i18n-dictionaries"

import dayjs from "dayjs"
import { shoulConnectUser, isIE } from "utils"
import { makeStyles } from "@material-ui/core/styles"
import { sendVerificationMail } from "services/auth"

import "./helpers.css"

const useStyles = makeStyles(theme => ({
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  clickable: {
    cursor: "pointer",
  },
  root: {
    color: theme.landing,
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}))
const styles = theme => ({
  root: {
    height: "100vh",
    overflow: "auto",
    backgroundSize: "cover",
  },
  buttonLabel: {
    color: theme.wisemblyColors.green,
  },
  switchXlOnMobile: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  loader: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: theme.zIndex.tooltip + 1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#bac1c3",
    background: "linear-gradient(45deg, #bac1c3, #ebeeef 60%)",
  },
  banner: {
    position: "fixed",
    zIndex: 9999,
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100px",
    background: "#ffe35f",
    color: "#394246",
  },
})

const injectInlineStyle = inline => {
  if (typeof window === `undefined`) return false

  document.head.insertAdjacentHTML("beforeend", `<style>${inline}</style>`)

  return true
}

const injectCss = file => {
  if (typeof window === `undefined`) return false

  if (!file.match(/^(https:\/\/|\/custom)(.*).css$/gi)) {
    console.warn(
      'wrong file format given: either give a "custom/file.css" or https external .css file'
    )
    return false
  }

  document.head.insertAdjacentHTML(
    "beforeend",
    `<link rel="stylesheet" type="text/css" href="${file}" />`
  )

  return true
}

// https://stackoverflow.com/questions/8578617/inject-a-script-tag-with-remote-src-and-wait-for-it-to-execute
const requireJS = file => {
  (function(d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) return
    js = d.createElement(s)
    js.id = id
    js.onload = function() {
      // console.log('loaded');
    }
    js.src = file
    fjs.parentNode.insertBefore(js, fjs)
  })(document, "script", "custom-js")
}

const UnverifiedMessage = () => {
  const classes = useStyles()

  return (
    <>
      <Box className={clsx("UnverifiedBlock", classes.description)}>
        {/* prettier-ignore */}
        <Typography align="center" variant="body2">
          {_t("Welcome to our website! You have received an email to validate your account.")}
        </Typography>
        <Typography align="center" variant="body2">
          {_t(
            "Please click on the validation button in this email to make sure you can access all workshops and sessions."
          )}
        </Typography>
      </Box>
      <Box
        onClick={() => {
          sendVerificationMail()
        }}
        className={clsx(classes.description, classes.clickable)}
      >
        <Typography align="center" variant="body2" className={classes.link}>
          {_t("Nothing received? Send me the confirmation email again.")}
        </Typography>
      </Box>
    </>
  )
}

class Layout extends Component {
  constructor(props) {
    super(props)

    const { page } = props

    if (get(page, "theme.css.activated", false)) {
      const file = get(page, "theme.css.file", "")
      const inline = get(page, "theme.css.inline", "")

      if (file.length) injectCss(file)
      if (inline.length) injectInlineStyle(inline)
    }

    if (
      get(page, "theme.js.activated", false) &&
      get(page, "theme.js.file", "").length
    )
      requireJS(get(page, "theme.js.file", ""))

    this.state = {
      loader:
        get(page, "loader.activated", false) ||
        !isEmpty(get(page, "wizMember", false)),
      isIE: isIE(),
      userEventsMap: {},
      userEventLoaded: false,
    }
  }

  displayLoginIfRequired() {
    const { page } = this.props
    const shouldBeMemberOfWiz = get(page, "wizMember", false)

    if (
      (get(page, "authenticationRequired", false) ||
        !isEmpty(shouldBeMemberOfWiz)) &&
      !isLoggedIn()
    ) {
      saveRedirectUrl(
        this.props.location.href.slice(this.props.location.origin.length)
      )
      emitter.emit(events.showLogin, true)
    }
  }

  displayUnverifAlertIfRequired() {
    const { page } = this.props

    if (!page.keyword) return

    if (
      get(page, "authenticationRequired", false) !== true &&
      get(page, "forceVerify", true) &&
      isLoggedIn() &&
      !isVerified()
    ) {
      emitter.emit(events.showUnverifiedFirewall, <UnverifiedMessage />)
    }
  }

  componentDidMount() {
    const { page } = this.props

    this.displayLoginIfRequired()
    this.displayUnverifAlertIfRequired()

    emitter.on(events.userConnected, () => {
      this.forceUpdate()
    })

    emitter.on(events.userLogout, () => {
      this.setState({ userEventLoaded: false })
      this.displayLoginIfRequired()
    })

    // priorise user locale
    const locale = getLocale(page)
    const dictionary = dictionaries[locale]

    if (dictionary) {
      i18n.load(dictionary)
      dayjs.locale(locale)
      this.forceUpdate()
      window.i18n = i18n
    }

    document.title = page.title || "Wisembly"

    const shouldWaitForUserData =
      get(page, "loader.shouldWaitForUserData", false) ||
      !isEmpty(get(page, "wizMember", false))

    if (this.state.loader && (!shouldWaitForUserData || !isLoggedIn())) {
      setTimeout(() => {
        this.setState({ loader: false })
        emitter.emit("app:rendered")
      }, get(page, "loader.durationSeconds", 1) * 1000)
    }

    emitter.on(events.userEventsUpdated, userEventsMap => {
      this.setState({ loader: false, userEventLoaded: true, userEventsMap })
      emitter.emit("app:rendered")
    })

    shoulConnectUser()
  }

  componentDidUpdate() {
    const { page } = this.props

    this.displayLoginIfRequired()
    this.displayUnverifAlertIfRequired()

    const shouldWaitForUserData =
      get(page, "loader.shouldWaitForUserData", false) ||
      !isEmpty(get(page, "wizMember", false))

    if (
      isLoggedIn() &&
      shouldWaitForUserData &&
      !this.state.loader &&
      !this.state.userEventLoaded
    ) {
      this.setState({ loader: true })
    }
  }

  render() {
    const { page, classes, children } = this.props
    const topBarActivated = isTopBarActive(page.theme || {})
    const headerActivated = isHeaderActive(page.theme || {})
    const backgroundImage = isEmpty(get(page, "theme.background.img", null))
      ? images.defaultBackground
      : page.theme.background.img
    const backgroundColor = get(
      page,
      "theme.background.applyBackgroundColor",
      false
    )
      ? get(page, "theme.background.color", null)
      : null
    let style = {}
    if (!isEmpty(backgroundColor)) {
      style = { backgroundColor }
    } else {
      style = {
        position: "relative",
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
      }
    }

    // needed to inject connected user as props in order to trigger re-render
    // when layout parent is forced re-rendered when user signin or logout
    // otherwise Layout parent will force re-render but Sections not cuz' unchanged
    const clonedChildren = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { user: getUser() })
      }
    })

    if (this.state.loader)
      return (
        <Container
          maxWidth="xl"
          style={style}
          className={clsx("Container", classes.root, classes.switchXlOnMobile)}
        >
          <Box className={classes.loader}>
            <CircularProgress color="secondary" />
          </Box>
        </Container>
      )

    return (
      <Container
        maxWidth="xl"
        style={style}
        className={clsx("Container", classes.root, classes.switchXlOnMobile)}
      >
        {topBarActivated && <TopBar title={page.title} />}

        {headerActivated && (
          <LandingHeader theme={page.theme} title={page.title} />
        )}

        {!(get(page, "authenticationRequired", false) && !isLoggedIn()) && (
          <Box className="LandingContent" display="flex" flex={1}>
            {clonedChildren}
          </Box>
        )}

        <Form page={page} user={getUser()} />
        <Login page={page} />
        <Firewall
          page={page}
          userEventsMap={this.state.userEventsMap}
          userEventLoaded={this.state.userEventLoaded}
          theme={page.theme}
        />
        <Alert />

        {this.state.isIE && (
          <Box class={classes.banner}>
            {/* prettier-ignore */}
            <Typography>
              {_t("We detected Internet Explorer as your browser. This webpage is not supported for it and you might experience weird displays/behaviours. Please, open this page with a recent supported browser like: Chrome, Firefox, Edge, Safari. Thanks")}
            </Typography>
          </Box>
        )}
      </Container>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  page: PropTypes.object,
  location: PropTypes.object,
  classes: PropTypes.object,
}
Layout.defaultProps = {
  page: { theme: {} },
  location: {},
}
export default withStyles(styles)(Layout)
