import React, { Component } from "react"
import clsx from "clsx"
import get from "lodash/get"
import Box from "@material-ui/core/Box"
import { withStyles } from "@material-ui/core/styles"
import { createUser, handleLogin, isValid } from "services/auth"
import emitter, { events } from "utils/emitter"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { _t } from "utils/i18n"
import Password from "./password"
import OAuthLinks from "./oAuthLinks"

const styles = theme => ({
  focus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  root: {
    paddingTop: theme.spacing(8),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    paddingBottom: theme.spacing(5),
    maxWidth: "400px",
    textAlign: "center",
  },
  description: {
    maxWidth: "400px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    maxWidth: "400px",
  },
  policy: {
    marginTop: theme.spacing(2),
    color: theme.wisemblyColors.grey,
    "& > *": {
      color: theme.wisemblyColors.grey,
    },
  },
  backButton: {
    marginTop: theme.spacing(2),
  },
})

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.wisemblyColors.white,
    backgroundColor: theme.wisemblyColors.green,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.green,
    },
    width: "100%",
  },
}))(Button)

class Signup extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: this.props.password || "",
      email: this.props.email || "",
      optin: false,
      errors: {},
      isValid: false,
      customCGUs: false,
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleSubmit = async event => {
    event.preventDefault()

    let result

    result = await createUser(this.state, this.props.page)

    if (result !== true && result.status === 409) {
      this.setState({
        isValid: false,
        errors: {
          email: _t("This email already exists, please try to login instead."),
        },
      })
      return
    }

    result = await handleLogin(this.state)

    if (result) {
      emitter.emit(events.hiddenLogin)
    }
  }

  isValid() {
    let errors = isValid({ ...this.state, type: "signup" })

    if (
      get(this.props.page, "login.cgu.activated") &&
      get(this.props.page, "login.cgu.mandatory", false) &&
      !this.state.customCGUs
    )
      errors.errors = {
        ...errors.errors,
        customCGUs: true,
      }

    this.setState({ isValid: Object.keys(errors).length === 0, errors })
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState(
      {
        [name]: value,
      },
      this.isValid
    )
  }

  render() {
    const { classes, showForm } = this.props
    const { email, password, errors, optin, isValid } = this.state
    const { title, description } = this.props.login

    return (
      <Box className={clsx("Signup", classes.focus)}>
        <Box display="flex" className={classes.root}>
          <Typography
            className={classes.title}
            variant="h3"
            dangerouslySetInnerHTML={{
              __html: title
                ? title
                : _t(
                    "Hello, welcome to Wisembly!<br />Would you like to register?"
                  ),
            }}
          />

          {description && (
            <Box
              className={clsx("SignupDesc", classes.description)}
              variant="div"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}

          <Button
            onClick={() =>
              showForm("signin", this.state.email, this.state.password)
            }
            className={classes.backButton}
            id="goToLogin"
          >
            <Typography variant="subtitle1">
              {_t("👋 Already have an account? Log in")}
            </Typography>
          </Button>

          <form onSubmit={this.handleSubmit} className={classes.form}>
            <TextField
              margin="normal"
              variant="outlined"
              label={_t("Your email")}
              name="email"
              value={email}
              onChange={this.handleChange}
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email : ""}
            />

            <Password
              showValidator
              label={_t("Choose a password")}
              password={password}
              error={errors.password}
              handleChange={this.handleChange}
            />

            {get(this.props.page, "login.cgu.activated") &&
              get(this.props.page, "login.cgu.content", "").length > 0 && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.customCGUs}
                      onChange={this.handleChange}
                      name="customCGUs"
                    />
                  }
                  label={`${get(this.props.page, "login.cgu.content")}${
                    get(this.props.page, "login.cgu.mandatory", false)
                      ? "*"
                      : ""
                  }`}
                />
              )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={optin}
                  onChange={this.handleChange}
                  name="optin"
                />
              }
              label={_t("I would like to receive updates and wisembly tips.")}
            />

            <ColorButton
              variant="contained"
              type="submit"
              disabled={!isValid}
              size="large"
            >
              {_t("Create my account")}
            </ColorButton>
            <Typography
              className={classes.policy}
              variant={"caption"}
              align="justify"
              dangerouslySetInnerHTML={{
                __html: _t(
                  "The data collected in this form is processed by Wisembly in order to manage your registration on the site. To learn more about the management of your personal data and to exercise your rights, we invite you to read our <a href='https://www.wisembly.com/en/privacy-policy'>Privacy Policy</a>"
                ),
              }}
            />
          </form>
          <OAuthLinks />
        </Box>
      </Box>
    )
  }
}

Signup.propTypes = {
  classes: PropTypes.object,
  showForm: PropTypes.func,
  login: PropTypes.object,
  email: PropTypes.string,
  password: PropTypes.string,
  page: PropTypes.object,
}

Signup.defaultProps = {
  email: "",
  password: "",
  page: {},
}

export default withStyles(styles)(Signup)
