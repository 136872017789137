import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import URI from "urijs"
import TopBar from "components/top-bar"
import get from "lodash/get"
import {
  isLoggedIn,
  isVerified,
  sendVerificationMail,
  logout,
} from "services/auth"
import emitter, { events } from "utils/emitter"
import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Backdrop from "@material-ui/core/Backdrop"
import { withStyles } from "@material-ui/core/styles"
import { _t } from "utils/i18n"
import Button from "components/mui/button"
import EmailIcon from "@material-ui/icons/Email"

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "50%",
    height: "50%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
      height: "100%",
    },
  },
  full: {
    width: "100vw",
    height: "100%",
  },
  close: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1900 !important",
    outline: "none !important",
    "&focus": {
      outline: "none !important",
    },
    height: "100%",
    "& .MuiBackdrop-invisible": {
      display: "none",
    },
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  label: {
    width: "800px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.5px",
    marginBottom: "30px",
  },
  clickable: {
    cursor: "pointer",
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    marginLeft: "3px",
  },
  iconBG: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    backgroundColor: theme.palette.text.primary,
    color: "#f7896d",
  },
  icon: {
    fontSize: theme.spacing(5),
  },
  firewallTitle: {
    fontWeight: 700,
    fontSize: "25px",
    letterSpacing: 0.5,
    lineHeight: "31.25px",
    marginBottom: "30px",
  },
  buttonText: {
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: 0.75,
    lineHeight: "16px",
    color: "#FFFFFF",
  },
})

class Firewall extends Component {
  constructor(args) {
    super(args)

    this.state = {
      verifSent: false,
    }
  }
  refresh() {
    window.location.reload(false)
  }
  render() {
    const { classes, page, userEventsMap, userEventLoaded } = this.props
    const path = new URI().path().replace("/", "")
    const shouldBeMemberOfWiz = get(page, "wizMember", false)
    const forceVerifiedAccount =
      get(page, "authenticationRequired", false) &&
      get(page, "forceVerify", true)

    let type = ""
    let open = false
    let title = ""

    if (
      shouldBeMemberOfWiz &&
      userEventLoaded &&
      isLoggedIn() &&
      !userEventsMap[shouldBeMemberOfWiz]
    ) {
      open = true
      type = "notWizMember"
      title = ""
    } else if (forceVerifiedAccount && isLoggedIn() && !isVerified()) {
      open = true
      type = "unverified"
      title = _t("You must verify your email")
    }

    return (
      <Modal
        className={clsx(
          "ConnectionModal",
          `ConnectionModal--${path}`,
          classes.paper
        )}
        open={open}
        onClose={() => {}}
        BackdropComponent={Backdrop}
        disableBackdropClick={true}
        BackdropProps={{
          invisible: true,
        }}
      >
        <Box height="100%" display="flex" flexDirection="column" width="100%">
          <TopBar title={page.title} />
          <Box className={clsx("FirewallContent", classes.root, classes.full)}>
            <Box className={classes.firewallTitle}>{title}</Box>

            {type === "unverified" && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="50%"
                  className={classes.iconBG}
                  my={4}
                >
                  <EmailIcon className={classes.icon} />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mb={2}
                >
                  <Typography>
                    {_t("To continue, you must validate your account")}
                  </Typography>
                  {/* prettier-ignore */}
                  <Typography>
                  {_t("You must have received an email that allows you to validate and access the next page.")}
                </Typography>
                </Box>
                {this.state.verifSent && (
                  <Typography align="center" variant="body2">
                    {_t("Email sent!")}
                  </Typography>
                )}

                {!this.state.verifSent && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      sendVerificationMail()
                      this.setState({ verifSent: true })
                    }}
                  >
                    {/* prettier-ignore */}
                    <Typography align="center" className={classes.buttonText}>
                      {_t("Nothing received? Send me the email again")}
                    </Typography>
                  </Button>
                )}
                <Box display="flex" mt={2}>
                  <Typography>
                    {_t(
                      "I have just validated my account, I can access the following "
                    )}
                  </Typography>
                  <Box onClick={() => this.refresh()} className={classes.link}>
                    <Typography>{_t("by clicking here.")}</Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {type === "notWizMember" && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box className={clsx("NotWizMember", classes.description)}>
                  <Typography
                    align="center"
                    variant="body2"
                    className={classes.label}
                  >
                    {_t("This email is not authorized to access this page.")}
                  </Typography>
                </Box>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => logout(() => emitter.emit(events.userLogout))}
                >
                  <Typography align="center" className={classes.buttonText}>
                    {_t("Log with another account")}
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    )
  }
}

Firewall.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.object,
  userEventsMap: PropTypes.array,
  userEventLoaded: PropTypes.bool,
  theme: PropTypes.shape({
    logo: PropTypes.shape({
      img: PropTypes.string,
    }),
    header: PropTypes.shape({
      activated: PropTypes.bool,
      fontColor: PropTypes.string,
    }),
  }),
}

Firewall.defaultProps = {
  page: {},
  userEventLoaded: false,
  userEventsMap: [],
}

export default withStyles(styles)(Firewall)
