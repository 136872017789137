import React, { Component } from "react"
import ReactMarkdown from "react-markdown"
import dayjs from "dayjs"

import PropTypes from "prop-types"
import clsx from "clsx"

import get from "lodash/get"
import isEqual from "lodash/isEqual"

import basil from "utils/basil"

import { _t } from "utils/i18n"

import Box from "@material-ui/core/Box"
import Modal from "@material-ui/core/Modal"
import Button from "@material-ui/core/Button"
import Backdrop from "@material-ui/core/Backdrop"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"

import { withStyles } from "@material-ui/core/styles"

import { isLoggedIn, getForm, answerForm, getFormAnswer } from "services/auth"

import emitter, { events, mountEvents, unmountEvents } from "utils/emitter"

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
    width: "80vw",
    height: "85vh",
    overflow: "auto",
    borderRadius: "6px",
    padding: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    outline: "none !important",
    "&focus": {
      outline: "none !important",
    },
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1501 !important",
  },
  wrapper: {
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
  },
  title: {
    margin: theme.spacing(2),
  },
  footer: {
    margin: theme.spacing(5),
  },
  item: {},
  // these are the 3 possible HTML types a Wisembly Survey offers
  P: {
    textAlign: "justify",
  },
  H1: {
    marginTop: theme.spacing(1),
    fontSize: "18px",
    fontWeight: 600,
  },
  H2: {
    fontWeight: 600,
  },
  closeModal: {
    position: "absolute",
    top: 0,
    right: 0,
  },
})

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.wisemblyColors.white,
    backgroundColor: theme.wisemblyColors.green,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.green,
    },
    width: "100%",
  },
}))(Button)

const getFormFromUrl = url => {
  try {
    const regex = /(\w+)#surveys\/(\w+)/gi
    const match = regex.exec(url)

    return { keyword: match[1], surveyHash: match[2] }
  } catch (error) {
    return {}
  }
}

const EVENTS = {
  [events.showPreliminaryForm]: "pop",
}

class Form extends Component {
  constructor(props) {
    super(props)

    const activated = get(props, "page.preliminaryForm.activated", false)
    const pop = get(props, "page.preliminaryForm.pop", true)
    const getTranslateKey = get(
      props,
      "page.preliminaryForm.submitButtonText",
      ""
    )

    const submitButtonTranslateKey = getTranslateKey?.length
      ? getTranslateKey
      : _t("Submit")

    const { keyword, surveyHash } = getFormFromUrl(
      get(props, "page.preliminaryForm.url", "")
    )
    const isValid = !!keyword && !!surveyHash

    this.state = {
      pop,
      activated,
      isValid,
      error: false,
      hidden: true,
      keyword,
      surveyHash,
      loading: true,
      survey: undefined,
      hasAnswered: undefined,
      answering: false,
      answer: {},
      thankYou: false,
      submitButtonTranslateKey,
    }
  }

  async componentDidMount() {
    this.retrieveAndDisplay()
    mountEvents(EVENTS, this)
  }

  componentWillUnmount() {
    emitter.off(events.showPreliminaryForm, this.pop)
    unmountEvents(EVENTS, this)
  }

  pop() {
    this.setState({ hidden: false })
  }

  // if current user changed, check again if we need to display form
  componentDidUpdate(nextProps) {
    if (!isEqual(nextProps.user, this.props.user)) this.retrieveAndDisplay()
  }

  async hasAnsweredForm() {
    const { keyword, surveyHash } = this.state

    // check if we have stored localstorage answer
    let answeredAt = basil.get(
      `${this.props.user.hash}:${keyword}:${surveyHash}`
    )
    if (answeredAt !== null) {
      emitter.emit(events.hasAnsweredPreliminaryForm, answeredAt)
      return true
    }

    // check then API if answer is present in localstorage
    const answers = await getFormAnswer(keyword, surveyHash)

    if (!answers || answers.length === 0) return false

    try {
      // retrieve last answer, store into basil
      answeredAt = dayjs(answers[0].answered_at).unix()
      basil.set(`${this.props.user.hash}:${keyword}:${surveyHash}`, answeredAt)
      emitter.emit(events.hasAnsweredPreliminaryForm, answeredAt)
    } catch (err) {
      return false
    }

    return true
  }

  async retrieveAndDisplay() {
    const { pop, activated, isValid, keyword, surveyHash } = this.state

    if (!activated || !isValid) return

    // user already answered, no need to show form
    if (await this.hasAnsweredForm()) {
      return this.setState({ hasAnswered: true })
    }

    // user did not answered, pop form if option set to true
    this.setState({ hidden: !pop, hasAnswered: false })

    // retrieve the survey, stop loading then
    const survey = await getForm(keyword, surveyHash)
    this.setState({ survey, loading: false })
  }

  renderForm() {
    const {
      pop,
      survey,
      answer,
      answering,
      submitButtonTranslateKey,
    } = this.state
    const { classes } = this.props

    return (
      <form onSubmit={event => this.onSubmit(event)}>
        <Box display="flex" className={classes.wrapper}>
          {!pop && (
            <Box className={classes.closeModal}>
              <IconButton
                aria-label="close"
                onClick={() => this.setState({ hidden: true })}
              >
                <Close />
              </IconButton>
            </Box>
          )}

          <Typography variant="h3" className={classes.title}>
            {survey.name}
          </Typography>
          <Typography variant="subtitle1" className={classes.desc}>
            {survey.description}
          </Typography>

          <Box className={clsx("FormBody", classes.body)}>
            {survey.items.map((item, i) => {
              // only text and html fields currently supported
              if (["text", "html"].indexOf(item.type) === -1) return null

              if (item.type === "text")
                return (
                  <TextField
                    key={i}
                    margin="normal"
                    variant="outlined"
                    label={item.label}
                    fullWidth
                    required={item.required}
                    value={get(answer[item.hash], "value", "")}
                    onChange={event => this.onChange(item, event.target)}
                  />
                )

              if (item.type === "html")
                return (
                  <Box
                    key={i}
                    className={clsx(
                      "FormHtmlItem",
                      classes.item,
                      classes[item.options.nodeName],
                      `Type_${item.options.nodeName}`
                    )}
                  >
                    <ReactMarkdown>{item.label}</ReactMarkdown>
                  </Box>
                )
            })}
          </Box>

          {this.state.error && !answering && (
            <Typography variant="subtitle1" className={classes.desc}>
              {_t("An error occured.")}
            </Typography>
          )}

          <Box className={classes.footer}>
            <ColorButton variant="contained" type="submit" size="large">
              {answering ? (
                <CircularProgress size={15} />
              ) : (
                submitButtonTranslateKey
              )}
            </ColorButton>
          </Box>
        </Box>
      </form>
    )
  }

  onChange(item, target) {
    const { answer } = this.state

    // only work for text items..
    const value = target.value

    answer[item.hash] = {
      hash: item.hash,
      value,
    }

    this.setState({ answer })
  }

  async onSubmit(event) {
    event.preventDefault()
    const { keyword, surveyHash, answer } = this.state

    this.setState({ answering: true })

    const result = await answerForm(keyword, surveyHash, Object.values(answer))

    if (result && result.message && result.status)
      return this.setState({ answering: false, error: true })

    this.setState({ thankYou: true, answering: false })

    const answeredAt = dayjs().unix()
    basil.set(`${this.props.user.hash}:${keyword}:${surveyHash}`, answeredAt)
    emitter.emit(events.hasAnsweredPreliminaryForm, answeredAt)

    setTimeout(() => {
      this.setState({ hidden: true })
    }, 5 * 1000)
  }

  render() {
    const { classes, page } = this.props
    const { survey, loading, thankYou, activated, isValid } = this.state
    const thankYouMessage = get(page, "preliminaryForm.thankYouMessage", false)

    // do not show if not activated or not valid
    if (!activated || !isValid) return null

    // do not show form is auth is required and user not connected yet
    if (get(page, "authenticationRequired", false) && !isLoggedIn()) return null

    return (
      <Modal
        className={clsx("FormModal", classes.paper)}
        open={!this.state.hidden}
        onClose={() => {}}
        BackdropComponent={Backdrop}
      >
        <Box className={clsx("FormWrapper", classes.root)}>
          {loading && (
            <Box className={classes.loader}>
              <CircularProgress color="secondary" />
            </Box>
          )}

          {!loading &&
            !thankYou &&
            get(survey, "hash", false) &&
            this.renderForm()}

          {get(survey, "message", false) && (
            <Typography>{survey.message}</Typography>
          )}

          {thankYou && (
            <Box>
              <Typography variant="h3" className={classes.title}>
                {_t("Thank you!")}
              </Typography>

              {thankYouMessage && (
                <Typography variant="subtitle1" className={classes.desc}>
                  {thankYouMessage}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    )
  }
}

Form.propTypes = {
  page: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
}

export default withStyles(styles)(Form)
