import React from "react"
import { _t } from "utils/i18n"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import images from "assets/img"
import IconButton from "@material-ui/core/IconButton"
import URI from "urijs"
import { makeHash } from "utils/makeHash"

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3, 0),
  },
  divider: {
    margin: theme.spacing(3, 0),
    color: theme.wisemblyColors.grey,
    position: "relative",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    border: "0",
    "&::before, &::after": {
      boxSizing: "border-box",
      content: "''",
      flex: "1 1 auto",
      borderTop: "1px solid #d5d9da",
    },
  },
  dividerLabel: {
    display: "inline-block",
    fontWeight: "600",
    flex: "0 0 auto",
    width: "2.85714rem",
    textAlign: "center",
  },
  oauth: {
    boxShadow: "0 2px 12px 0 rgba(42, 67, 123, 0.18)",
    backgroundColor: theme.wisemblyColors.white,
    marginRight: theme.spacing(3),
  },
  image: {
    width: "40px",
    height: "40px",
  },
  images: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
}))

const OAuthLinks = () => {
  const classes = useStyles()

  const getUrl = provider => {
    let redirectPath = new URI(`./`)
    redirectPath
      .segment("signin")
      .addSearch("app_id", process.env.API_APP_ID)
      .addSearch(
        "hash",
        makeHash(process.env.API_APP_ID + process.env.API_APP_SECRET)
      )
      .addSearch("redirect_success", window.location.href)
      .addSearch("redirect_failure", window.location.href)

    let url = new URI(`./`)
    url
      .segment("connect")
      .segment(provider)
      .addSearch(
        "redirect_path",
        redirectPath.absoluteTo(process.env.OAUTH_BASE).toString()
      )

    return url.absoluteTo(process.env.OAUTH_BASE).toString()
  }

  return (
    <Box className={clsx("OAuthLinks", classes.root)}>
      <Box className={classes.divider}>
        <Typography className={classes.dividerLabel} variant="caption">
          {_t("OR")}
        </Typography>
      </Box>
      <Box className={classes.images}>
        <IconButton href={getUrl("google")} className={classes.oauth}>
          <img
            className={classes.image}
            src={images.logoGoogle}
            alt={`Google`}
          />
        </IconButton>
        <IconButton href={getUrl("azure")} className={classes.oauth}>
          <img
            className={classes.image}
            src={images.logoMicrosoft}
            alt={`Microsoft`}
          />
        </IconButton>
        <IconButton href={getUrl("linkedin")} className={classes.oauth}>
          <img
            className={classes.image}
            src={images.logoLinkedin}
            alt={`LinkedIn`}
          />
        </IconButton>
      </Box>
    </Box>
  )
}

export default OAuthLinks
