import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import images from "assets/img"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  logo: {
    width: "100%",
    maxWidth: 300,
    maxHeight: 300,
    [theme.breakpoints.down("md")]: {
      maxWidth: 200,
      maxHeight: 200,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 150,
      maxHeight: 150,
    },
  },
  landingTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: props =>
      props.titleColor ? props.titleColor : theme.palette.text.white,
  },
}))

const LandingHeader = ({ theme, title }) => {
  const classes = useStyles({
    titleColor: get(theme, "header.fontColor", null),
  })
  return (
    <Box
      display="flex"
      mt={4}
      mb={2}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className="LandingHeader"
    >
      <Box className={clsx("LogoWrapper", classes.logoBox)}>
        <img
          className={clsx("Logo", classes.logo)}
          component="img"
          src={
            isEmpty(get(theme, "logo.img", null))
              ? images.defaultLogo
              : theme.logo.img
          }
          alt={`logo`}
        />
      </Box>
      <Box>
        <Typography
          variant="h3"
          className={clsx("LandingHeaderTitle", classes.landingTitle)}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  )
}
LandingHeader.propTypes = {
  theme: PropTypes.shape({
    logo: PropTypes.shape({
      img: PropTypes.string,
    }),
    header: PropTypes.shape({
      activated: PropTypes.bool,
      fontColor: PropTypes.string,
    }),
  }),
  title: PropTypes.string,
}
export default LandingHeader
