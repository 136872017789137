import { fetchApiData } from "utils/fetchApiData"
import URI from "urijs"
import sha1 from "crypto-js/sha1"
import get from "lodash/get"
import { setCookie, getCookie } from "utils/basil"
import { _t } from "utils/i18n"
import { EMAIL_REGEX, PASSWORD_DIGIT_REGEX, PASSWORD_LETTER_REGEX } from "utils"
import emitter, { events } from "utils/emitter"
import isEmpty from "lodash/isEmpty"
import { getLocale } from "utils/i18n"
import { setWindow } from "utils/window"

export let userEventsMap = {}
export let userData = {}

export const isBrowser = () => typeof window !== "undefined"

export const getUserSession = () =>
  getCookie({ key: "api_session.current" }) || {}

export const setUserSession = value => {
  setCookie({
    key: "api_session.current",
    value,
    conf: { expireDays: 30 },
  })
}

export const getUser = () => userData
export const setUser = user => (userData = user)

// expose it to window for custom js scripts
setWindow(`getUser`, getUser)

const clear = () => {
  setUser({})
  setUserSession({})
}

const getToken = () => {
  const { token } = getUserSession()
  return token
}

export const isLoggedIn = () => {
  const { token, is_user } = getUserSession()
  return !!(token && is_user)
}
export const isVerified = () => {
  const user = getUser()
  if (isEmpty(user)) return false
  return user.is_verified
}

export const hasToken = () => {
  return !!getToken()
}

const setUserFromData = async apiSession => {
  if (isEmpty(apiSession)) return clear()

  const previous = getUserSession()
  const { token, refreshed_at, refresh_token } = apiSession
  const user = apiSession.user || {}

  setUserSession({
    token,
    refresh_token: refresh_token || previous.refresh_token,
    refreshed_at,
    is_user: !!user.hash,
  })

  setUser(user)

  if (user && user.id) emitter.emit(events.userConnected)
}

export const isValid = ({ email, password, code, type }) => {
  const errors = {}

  if (type === "confirm_twofa") {
    if (!code) {
      errors.code = "Required"
    }

    return errors
  }

  if (type !== "forgot_pwd") {
    if (!password) {
      errors.password = "Required"
    } else if (
      "signup" === type &&
      (password.length < 8 ||
        !PASSWORD_LETTER_REGEX.test(password) ||
        !PASSWORD_DIGIT_REGEX.test(password))
    ) {
      errors.password = "Invalid password"
    }
  }

  if (!email) {
    errors.email = "Required"
  } else if (!EMAIL_REGEX.test(email)) {
    errors.email =
      type === "signup"
        ? _t("Please enter a valid email address")
        : _t("Incorrect email address")
  }

  return errors
}

export const createUser = async ({ email, password, optin }, page = {}) => {
  try {
    const baseTarget = new URI(`./`)
      .segment(`user`)
      .addSearch(`keyword`, window.location.href)

    if (get(page, "mail.welcome", true) === false)
      baseTarget.addSearch(`notify`, false)

    if (!hasToken()) {
      await handleLogin({ email: null, password: null })
    }

    const session = getUserSession()

    await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
      options: {
        method: `POST`,
        body: {
          lang: getLocale(page),
          password,
          email,
          optin,
          signup_referrer: URI().hostname() + URI().path(),
        },
      },
    })
  } catch (error) {
    clear()
    return { message: error.message, status: error.statusCode }
  }

  return true
}

export const handleLostPassword = async ({ email }) => {
  try {
    let baseTarget = new URI(`./`)
    baseTarget.segment(`user`).segment(`lost-password`)

    if (!hasToken()) {
      await handleLogin({ email: null, password: null })
    }

    const session = getUserSession()

    await fetchApiData(baseTarget, {
      session: { token: session.token },
      options: {
        method: `POST`,
        body: {
          email,
        },
      },
    })

    clear()

    return true
  } catch (e) {
    clear()
    console.error("error in forgot password", e)
  }

  return false
}

export const confirmTwoFA = async ({ twofa_token, twofa_code }) => {
  try {
    let baseTarget = new URI(`./`)
    baseTarget
      .segment(`authentication`)
      .segment(`twofa`)
      .segment(`verify`)

    if (!hasToken()) {
      await handleLogin({ email: null, password: null })
    }
    const session = getUserSession()

    const resourceData = await fetchApiData(baseTarget, {
      session: { token: session.token },
      options: {
        method: `POST`,
        body: {
          twofa_token,
          twofa_code,
        },
      },
    })

    await setUserFromData(resourceData)

    return true
  } catch (e) {
    clear()
    console.error("error in confirm twofa", e)
  }

  return false
}

export const ottLogin = async ott => {
  let baseTarget = new URI(`./`)
  baseTarget.segment(`ott-authentication`)
  baseTarget.setQuery("ott", ott)

  try {
    const resourceData = await fetchApiData(baseTarget.normalize())
    await setUserFromData(resourceData)

    return resourceData
  } catch (e) {
    clear()
  }

  return {}
}

export const sendVerificationMail = async () => {
  //slug: ["user", "verification-email"],
  try {
    const session = getUserSession()
    const baseTarget = new URI(`./`)
      .segment(`user`)
      .segment(`verification-email`)
      .addSearch(`keyword`, window.location.href)

    await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
      options: {
        method: "POST",
        body: {},
      },
    })
    console.log("email should be sent")
    //emmit mail sent
    emitter.emit(events.confirmationEmailSent, _t("Email sent"))
  } catch (e) {
    console.error("error while sending verifiaction mail", e)
  }
}

const refreshToken = async () => {
  try {
    const session = getUserSession()
    if (!session) return false

    const baseTarget = new URI(`./`).segment(`authentication`)

    const resourceData = await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
      options: {
        method: "PUT",
        body: {
          refresh: session.refreshToken,
        },
      },
    })

    await setUserFromData(resourceData)

    return true
  } catch (e) {
    clear()
    return false
  }
}

export const checkLogin = async () => {
  //getToken from cookies

  try {
    const savedToken = getToken()

    if (!savedToken) return false

    const baseTarget = new URI(`./`).segment(`authentication`)

    const resourceData = await fetchApiData(baseTarget.normalize(), {
      session: { token: savedToken },
    })

    await setUserFromData(resourceData)
    return true
  } catch (e) {
    if (get(e, "extra.code") === "expired_token") {
      return await refreshToken()
    }

    //clean cookies
    clear()

    // force reflow of the app
    emitter.emit(events.userLogout)

    return false
  }
}

export const handleLogin = async ({ email, password } = {}) => {
  try {
    let baseTarget = new URI(`./`)
    baseTarget.segment(`authentication`)

    const userHash = sha1(
      (email ? email : "") + process.env.API_APP_ID + process.env.API_APP_SECRET
    ).toString()

    const resourceData = await fetchApiData("authentication", {
      options: {
        withExtra: true,
        method: `POST`,
        body: {
          app_id: process.env.API_APP_ID,
          hash: userHash,
          secret: password,
          email,
        },
      },
    })

    const { data, extra } = resourceData
    const { twofa_token } = extra

    if (twofa_token) {
      emitter.emit(events.showLogin, true, "confirm_twofa", twofa_token)
      return false
    }

    await setUserFromData({
      ...data,
      refresh_token: extra.refresh_token,
    })

    return true
  } catch (e) {
    console.error("error in login", e)
  }

  return false
}

export const logout = callback => {
  clear()
  callback()
}

export const getEvents = async () => {
  const user = getUser()
  const session = getUserSession()

  try {
    const baseTarget = new URI(`./`)
      .segment(`user`)
      .segment(user.hash)
      .segment(`wiz`)

    const wizes = await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
    })

    for (let i = 0; i < wizes.length; i++)
      userEventsMap[wizes[i].keyword] = wizes[i]

    // tell the world we have fetched the user Wizes
    //it's better to put userEventsMap as argument of emitter
    //because if component is mounted before emit and import userEventsMap
    //userEventsMap value won't change event after update it's weird i know
    emitter.emit(events.userEventsUpdated, userEventsMap)
  } catch (error) {
    emitter.emit(events.userEventsUpdated, userEventsMap)
    emitter.emit(events.userEventsClear)
    return { message: error.message, status: error.statusCode }
  }
}

export const getForm = async (keyword, hash) => {
  const session = getUserSession()

  try {
    const baseTarget = new URI(`./`)
      .segment(`event`)
      .segment(keyword)
      .segment(`surveys`)
      .segment(hash)

    const form = await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
    })

    return form
  } catch (error) {
    return { message: error.message, status: error.statusCode }
  }
}

export const getFormAnswer = async (keyword, hash) => {
  const session = getUserSession()
  const user = getUser()

  if (!user.hash) return false

  try {
    const baseTarget = new URI(`./`)
      .segment(`event`)
      .segment(keyword)
      .segment(`surveys`)
      .segment(hash)
      .segment(`answers`)
      .segment(`user`)
      .segment(user.hash)

    const response = await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
    })

    return response
  } catch (error) {
    return { message: error.message, status: error.statusCode }
  }
}

export const answerForm = async (keyword, hash, answer) => {
  if (!hasToken()) {
    await handleLogin({ email: null, password: null })
  }

  const session = getUserSession()

  try {
    const baseTarget = new URI(`./`)
      .segment(`event`)
      .segment(keyword)
      .segment(`surveys`)
      .segment(hash)
      .segment(`answers`)

    const response = await fetchApiData(baseTarget.normalize(), {
      session: { token: session.token },
      options: {
        method: "POST",
        body: {
          items: answer,
        },
      },
    })

    return response
  } catch (error) {
    return { message: error.message, status: error.statusCode }
  }
}

export const clearEvents = () => {
  userEventsMap = {}

  emitter.emit(events.userEventsUpdated, userEventsMap)
  emitter.emit(events.userEventsClear)
}
