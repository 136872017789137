import { withStyles } from "@material-ui/styles"
import MuiButton from "@material-ui/core/Button"

/*
for now i only add customisation for color="secondary"&&variant="contained" combinaison  and color="success"&&variant="contained" combinaison plus label classe

i created a new fontWeight attribute to quickly customise label fontWeight
i created a new textColor attribute to quickly customise label color
i created a new height attribute to quickly customise height value for the button
usage
import Button from "appComponents/Mui/Button"

<Button color="secondary" variant="contained" fontWeight="normal" fontWeight="normal" textColor="white" height="auto" >
</Button>
*/

const Button = withStyles(theme => {
  return {
    //some customisation on root in we want in the futur
    root: props =>
      props.color === "success" && props.variant === "contained"
        ? {
            backgroundColor: theme.wisemblyColors.green,
            "&:hover": {
              backgroundColor: theme.wisemblyColors.green,
            },
            width: "100%",
            fontSize: "1.25rem",
            fontWeight: "bold",
            lineHeight: "1.6",
            textTransform: "none",
            height: props.height ? props.height : "40px",
          }
        : props.color === "disabled" && props.variant === "contained"
        ? {
            backgroundColor: theme.palette.header.backgroundHelpButton,
            "&:hover": {
              backgroundColor: theme.palette.header.backgroundHelpButton,
            },
            width: "100%",
            fontSize: "1.25rem",
            fontWeight: "bold",
            lineHeight: "1.6",
            textTransform: "none",
            height: props.height ? props.height : "40px",
          }
        : {
            height: props.height ? props.height : "40px",
          },
    containedSecondary: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.5,
      },
    },
    label: {
      fontSize: props =>
        props.size === "small"
          ? "10px"
          : props.size === "large"
          ? "20px"
          : "16px",
      letterSpacing: props =>
        props.size === "small"
          ? "0.49px"
          : props.size === "large"
          ? "0.97px"
          : "0.78px",
      lineHeight: "normal",
      textAlign: "center",
      fontWeight: props => (props.fontWeight ? props.fontWeight : "bold"),
      color: props =>
        props.color === "success" && props.variant === "contained"
          ? theme.wisemblyColors.white
          : props.color === "disabled" && props.variant === "contained"
          ? theme.palette.action.active
          : props.textColor
          ? props.textColor
          : theme.palette.secondary.contrastText,
      textTransform: "none",
    },
    sizeSmall: {
      //fontSize: "10px",
    },
    contained: {
      //backgroundColor: theme.palette.header.backgroundHelpButton,
      "&:hover": {
        //#d5d5d5
        //backgroundColor: theme.palette.header.backgroundHelpButton,
      },
    },
  }
})(MuiButton)

export default Button
