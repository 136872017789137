import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { confirmTwoFA, isValid } from "services/auth"
import emitter, { events } from "utils/emitter"
import { _t } from "utils/i18n"

const styles = theme => ({
  focus: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  root: {
    paddingTop: theme.spacing(8),
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "400px",
  },
})

const ColorButton = withStyles(theme => ({
  root: {
    color: theme.wisemblyColors.white,
    backgroundColor: theme.wisemblyColors.green,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.green,
    },
    width: "100%",
  },
}))(Button)

class ConfirmTwofa extends Component {
  constructor(props) {
    super(props)

    this.state = {
      code: "",
      token: this.props.token,
      isValid: false,
      errors: {},
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleSubmit = async event => {
    event.preventDefault()
    const { token, code } = this.state
    const result = await confirmTwoFA({ twofa_code: code, twofa_token: token })

    if (result) {
      emitter.emit(events.hiddenLogin)
    }
  }

  isValid() {
    const errors = isValid({ ...this.state, type: "confirm_twofa" })
    this.setState({ isValid: Object.keys(errors).length === 0, errors })
  }

  handleChange(event) {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value
    const name = target.name

    this.setState(
      {
        [name]: value,
      },
      this.isValid
    )
  }

  render() {
    const { classes } = this.props
    const { code, isValid, errors } = this.state

    return (
      <Box className={clsx("ConnectFormTwoFA", classes.focus)}>
        <Box display="flex" className={classes.root}>
          <form onSubmit={this.handleSubmit} className={classes.form}>
            {/* prettier-ignore */}
            <Typography variant="subtitle2">
              {_t("You will receive a 4-digit code on the SMS number you have given us.")}
            </Typography>
            <TextField
              margin="normal"
              variant="outlined"
              label={_t("activation code")}
              name="code"
              value={code}
              onChange={this.handleChange}
              fullWidth
              error={!!errors.code}
              helperText={errors.code ? errors.code : ""}
            />
            <ColorButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!isValid}
            >
              {_t("Validation code")}
            </ColorButton>
          </form>
        </Box>
      </Box>
    )
  }
}

ConfirmTwofa.propTypes = {
  classes: PropTypes.object,
  token: PropTypes.string.isRequired,
}

ConfirmTwofa.defaultProps = {}

export default withStyles(styles)(ConfirmTwofa)
