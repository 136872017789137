import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import isArray from "lodash/isArray"
import URI from "urijs"
import { setUser, ottLogin } from "services/auth"

//http://localhost:8000/amirmuerte?today=22-11-2020&noTopbar=true&noHeader=true&iframe=true
export const isTopBarActive = theme => {
  const search = URI().search(true)
  if (search.noTopbar === "true") return false
  if (!get(theme, "topBar.activated")) return false
  return true
}

export const isHeaderActive = theme => {
  const search = URI().search(true)
  if (search.noHeader === "true") return false
  if (!get(theme, "header.activated")) return false
  return true
}

export const shouldHideOnQueryArg = queryArg => {
  if (isEmpty(queryArg)) return false

  const { hideOnQueryArg } = URI().search(true)

  if (!hideOnQueryArg) return false

  if (isArray(hideOnQueryArg) && hideOnQueryArg.indexOf(queryArg) !== -1)
    return true

  return hideOnQueryArg === queryArg
}
export const shoulConnectUser = async () => {
  const { token, refresh_token, ott } = URI().search(true)

  if (ott) {
    await ottLogin(ott)

    //checkLogin
    redirectToUrl(new URI(window.location).removeSearch("ott").toString())

    return
  }

  if (!token || !refresh_token) return false

  setUser({ token, refreshToken: refresh_token })

  //checkLogin
  redirectToUrl(
    new URI(window.location)
      .removeSearch("token")
      .removeSearch("refresh_token")
      .toString()
  )

  return true
}

export const getRedirectUrl = () => {
  return window.localStorage.getItem("gatsbyRedirectUrl")
    ? window.localStorage.getItem("gatsbyRedirectUrl")
    : "/"
}
export const saveRedirectUrl = url => {
  window.localStorage.setItem("gatsbyRedirectUrl", url)
}
export function redirectToUrl(newUrl, force = false) {
  if (typeof window.history.pushState !== "undefined" && !force) {
    return window.history.replaceState({}, document.title, newUrl)
  }

  window.location.href = newUrl
}

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export const PASSWORD_LETTER_REGEX = new RegExp(/^(?=.*[a-z])(?=.*[A-Z]).+$/)

export const PASSWORD_DIGIT_REGEX = new RegExp(/[0-9]/)

// https://stackoverflow.com/questions/49986720/how-to-detect-internet-explorer-11-and-below-versions/49986758
export const isIE = () => {
  if (typeof window === `undefined`) return false

  const ua = window.navigator.userAgent //Check the userAgent property of the window.navigator object
  const msie = ua.indexOf("MSIE ") // IE 10 or older
  const trident = ua.indexOf("Trident/") //IE 11

  return msie > 0 || trident > 0
}
