import URI from "urijs"

import { fetch } from "utils/fetch"

export async function fetchApiData(
  target,
  { session = null, options = {} } = {}
) {
  if (new URI(target).is(`relative`))
    target = new URI(target).absoluteTo(process.env.API_BASE).toString()

  let headers = {}

  if (session && session.token) headers[`Wisembly-Token`] = session.token

  const response = await fetch(target, Object.assign({ headers }, options))

  if (options.withExtra) {
    return { data: response.data, extra: response.extra }
  }

  return response.data
}
