import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import CheckIcon from "@material-ui/icons/Check"
import FormHelperText from "@material-ui/core/FormHelperText"
import { _t } from "utils/i18n"
import { PASSWORD_DIGIT_REGEX, PASSWORD_LETTER_REGEX } from "utils"

const styles = theme => ({
  validator: {
    color: theme.wisemblyColors.grey,
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
  },
  success: {
    color: theme.wisemblyColors.green,
  },
})

class Password extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPassword: false,
    }
  }

  render() {
    const {
      classes,
      label,
      showValidator,
      handleChange,
      password,
      error,
    } = this.props
    const { showPassword } = this.state

    return (
      <>
        <FormControl
          variant="outlined"
          margin="normal"
          fullWidth
          error={!!error}
        >
          <InputLabel htmlFor="input-password">{label}</InputLabel>
          <OutlinedInput
            id="input-password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={password}
            label={label}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={_t("Show password")}
                  onClick={() => this.setState({ showPassword: !showPassword })}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && (
            <FormHelperText id="my-helper-text">{error}</FormHelperText>
          )}
        </FormControl>
        {showValidator && (
          <Box className={classes.validator}>
            <Typography
              className={clsx(password.length >= 8 ? classes.success : null)}
              variant="caption"
            >
              <CheckIcon fontSize="small" /> {_t("8 characters or more")}
            </Typography>
            <Typography
              className={clsx(
                PASSWORD_LETTER_REGEX.test(password) ? classes.success : null
              )}
              variant="caption"
            >
              <CheckIcon fontSize="small" />{" "}
              {_t("At least one uppercase and one lowercase letter")}
            </Typography>
            <Typography
              className={clsx(
                PASSWORD_DIGIT_REGEX.test(password) ? classes.success : null
              )}
              variant="caption"
            >
              <CheckIcon fontSize="small" /> {_t("At least one digit")}
            </Typography>
          </Box>
        )}
      </>
    )
  }
}

Password.propTypes = {
  classes: PropTypes.object,
  showValidator: PropTypes.bool,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  password: PropTypes.string,
  error: PropTypes.string,
}

Password.defaultProps = {
  showValidator: false,
}

export default withStyles(styles)(Password)
