import Jed from "jed"

import basil from "utils/basil"

import { getWindow } from "utils/window"

export const DEFAULT_LOCALE = "en"

class I18n {
  constructor() {
    this.jed = new Jed({})
  }

  translate(arg) {
    return this.jed.translate(arg)
  }

  load(json) {
    this.jed = new Jed(json)
  }

  default() {
    this.jed = new Jed({})
  }
}

function replace(string, replacements) {
  for (let key in replacements) {
    string = string.replace(new RegExp(`{${key}}`, "gi"), replacements[key])
  }

  return string
}

const i18n = new I18n()

/*
 * I18nTranslate('key')
 * I18nTranslate('key {dog} foo', { dog })
 * I18nTranslate('key {count} {dog}', 'plural {count} {dog}', count, { dog, count })
 */
export function i18nTranslate(...args) {
  let value = i18n.translate(args[0])

  if (args.length > 2) {
    value = value.ifPlural(args[2], args[1]).fetch(args[2])

    return args.length === 4 ? replace(value, args[3]) : value
  }

  value = value.fetch()

  return args.length === 2 ? replace(value, args[1]) : value
}

export function getLocale(page = {}) {
  if (page && page.lang) return page.lang

  const navigator = getWindow(`navigator`, {
    language: "fr",
    userLanguage: "fr",
  })

  let navLanguage = navigator.language || navigator.userLanguage

  if (navLanguage.indexOf("-") !== -1) navLanguage = navLanguage.split("-")[0]

  if (navLanguage.indexOf("_") !== -1) navLanguage = navLanguage.split("_")[0]

  const locale = basil.get("user:locale") || navLanguage

  return locale
}

export function setLocale(locale) {
  basil.set("user:locale", locale)
}

export function _t() {
  return i18nTranslate(...arguments)
}

export function _n() {
  return i18nTranslate(...arguments)
}

export default i18n
