import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import URI from "urijs"

import get from "lodash/get"

import { isLoggedIn } from "services/auth"
import emitter, { events } from "utils/emitter"

import Modal from "@material-ui/core/Modal"
import Box from "@material-ui/core/Box"
import Backdrop from "@material-ui/core/Backdrop"
import { withStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import Close from "@material-ui/icons/Close"

import Signup from "components/connection/signup"
import Signin from "components/connection/signin"
import ForgotPwd from "components/connection/forgotPassword"
import ConfirmTwoFa from "components/connection/confirmTwofa"

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: "100vw",
    height: "100vh",
    overflow: "auto",
  },
  close: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1900 !important",
    outline: "none !important",
    "&focus": {
      outline: "none !important",
    },
  },
})

export const type = {
  signup: `signup`,
  signin: `signin`,
  confirmTwofa: `confirm_twofa`,
  forgotPwd: `forgot_pwd`,
}

class LoginModal extends Component {
  constructor(props) {
    super(props)

    const search = new URI().search(true)
    const type = get(props.page, "login.defaultScreen", "signup")

    this.state = {
      type,
      email: search.email || "",
      password: "",
      hidden: true,
      forceShouldConnect: this.props.forceConnection,
      twofa_token: null,
    }
  }

  componentDidMount() {
    emitter.on(events.showLogin, (forceConnection, type, twofa_token) => {
      this.setState({
        hidden: false,
        forceShouldConnect:
          forceConnection !== undefined
            ? forceConnection
            : this.props.forceConnection,
        type: type !== undefined ? type : this.state.type,
        twofa_token: twofa_token,
      })
    })

    emitter.on(events.hiddenLogin, () => {
      this.setState({
        hidden: true,
      })
    })
  }

  componentDidUpdate() {
    if (isLoggedIn()) {
      // or close modal
      if (!this.state.hidden) {
        this.setState({ hidden: true })
      }
    }
  }

  closeModal = () => {
    if (!this.state.forceShouldConnect) {
      this.setState({ hidden: true })
    }
  }

  getForm = () => {
    const login = this.props.page.login || {}

    switch (this.state.type) {
      case type.signin:
        return (
          <Signin
            login={login}
            email={this.state.email}
            password={this.state.password}
            page={this.props.page}
            showForm={(newType, email, password) =>
              this.setState({ type: newType, email, password })
            }
          />
        )
      case type.confirmTwofa:
        return <ConfirmTwoFa token={this.state.twofa_token} />
      case type.forgotPwd:
        return (
          <ForgotPwd
            email={this.state.email}
            showForm={(newType, email) =>
              this.setState({ type: newType, email })
            }
          />
        )
      case type.signup:
      default:
        return (
          <Signup
            login={login}
            page={this.props.page}
            email={this.state.email}
            password={this.state.password}
            showForm={(newType, email, password) =>
              this.setState({ type: newType, email, password })
            }
          />
        )
    }
  }

  render() {
    const { classes } = this.props
    const { forceShouldConnect } = this.state
    const path = new URI().path().replace("/", "")

    return (
      <Modal
        className={clsx(
          "ConnectionModal",
          `ConnectionModal--${path}`,
          classes.paper
        )}
        open={!this.state.hidden}
        onClose={this.closeModal}
        BackdropComponent={Backdrop}
      >
        <Box className={clsx("ConnexionWrapper", classes.root)}>
          {!forceShouldConnect && (
            <Box className={classes.close}>
              <IconButton aria-label="close" onClick={() => this.closeModal()}>
                <Close />
              </IconButton>
            </Box>
          )}
          {this.getForm()}
        </Box>
      </Modal>
    )
  }
}

LoginModal.propTypes = {
  forceConnection: PropTypes.bool,
  classes: PropTypes.object,
  type: PropTypes.string,
  page: PropTypes.object,
}

LoginModal.defaultProps = {
  forceConnection: false,
  type: type.signup,
  page: {},
}

export default withStyles(styles)(LoginModal)
