//import WisemblyLogoLight from "./signe-logo-color.svg";

export default {
  bgHomepage: require("./bg-homepage.svg"),
  WisemblyLogoLight: require("./signe-logo-color.svg"),
  defaultLogo: require("./defaultLogo.png"),
  defaultBackground: require("./defaultBackground.jpg"),
  logoGoogle: require("./google.svg"),
  logoMicrosoft: require("./microsoft.svg"),
  logoLinkedin: require("./linkedin.svg"),
}
