import React, { Component } from "react"
import { events, mountEvents, unmountEvents } from "utils/emitter"
import Snackbar from "@material-ui/core/Snackbar"
import UiAlert from "@material-ui/lab/Alert"

const ALERT_VANISH_TIME_MS = 5000

const EVENTS = {
  [events.confirmationEmailSent]: "onConfirmEmailSentVanish",
  [events.showUnverifiedFirewall]: "onUnverifiedAccount",
  [events.userRegistered]: "onUserRegistered",
}

class Alert extends Component {
  constructor(props) {
    super(props)

    this.state = {
      type: null,
      hidden: true,
      content: null,
    }

    this.onClose = this.onClose.bind(this)
  }

  componentDidMount() {
    mountEvents(EVENTS, this)
  }

  componentWillUnmount() {
    unmountEvents(EVENTS, this)
  }

  onError(content) {
    this.setState({
      type: "error",
      hidden: false,
      content: content,
    })
  }

  onSuccess(content) {
    this.setState({
      type: "success",
      hidden: false,
      content: content,
    })
  }

  onConfirmEmailSentVanish(content, vanishTimeMs = ALERT_VANISH_TIME_MS) {
    this.onSuccess(content)

    setTimeout(() => {
      this.onClose()
    }, vanishTimeMs)
  }

  onUserRegistered(content, vanishTimeMs = ALERT_VANISH_TIME_MS) {
    this.onSuccess(content)

    setTimeout(() => {
      this.onClose()
    }, vanishTimeMs)
  }

  onUnverifiedAccount(content) {
    this.onError(content)
  }

  onErrorVanish(content, vanishTimeMs = ALERT_VANISH_TIME_MS) {
    this.onError(content)

    setTimeout(() => {
      this.onClose()
    }, vanishTimeMs)
  }

  onClose() {
    this.setState({ hidden: true })
  }

  computeClass(type) {
    switch (type) {
      default:
        return "success"
      case "success":
      case "error":
      case "warning":
        return type
    }
  }
  onClickAway() {
    return false
  }
  render() {
    return (
      <Snackbar
        autoHideDuration={null}
        open={!this.state.hidden}
        onClose={this.onClose}
        ClickAwayListenerProps={{ onClickAway: this.onClickAway }}
      >
        <UiAlert
          onClose={this.onClose}
          severity={this.computeClass(this.state.type)}
        >
          {this.state.content}
        </UiAlert>
      </Snackbar>
    )
  }
}

export default Alert
